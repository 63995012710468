import * as React from "react"
import isEqual from "lodash.isequal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StoreContext } from "../../context/store-context"
import { AddToCart } from "../../components/buttons/add-to-cart"
import { NumericInput } from "../../components/products/numeric-input"
import { formatPrice } from "../../utils/format-price"
import { Disclosure, Tab } from "@headlessui/react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import { ComponentMotion } from "../../utils/componentMotion"
import {
  productImageList,
  productImageListItem,
  scrollForMore,
} from "./product-page.module.css"

const ProductInfo = ({ product, cms }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,

    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value
    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]
    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })
    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const Bold = ({ children }) => (
    <span className="font-semibold">{children}</span>
  )
  const Italic = ({ children }) => <span className="italic">{children}</span>

  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="text-base xl:text-lg pb-3">{children}</div>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <div className="text-xl text-gray-900 font-semibold pt-4 pb-3">
          {children}
        </div>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <div className="text-large text-gray-900 font-normal underline pt-4 pb-3">
          {children}
        </div>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <div className="text-sm pb-3">{children}</div>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc pl-6">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="list-decimal pl-6 pb-0">{children}</ol>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => {
        return (
          <a
            href={data.uri}
            target="_blank"
            rel="noopener noreferrer"
            className="font-semibold text-pwxBlue hover:underline"
          >
            {children}
          </a>
        )
      },
    },
  }
  return (
    <section className="">
      <section className="bg-grayBg border-b border-secondaryPale">
        <div className="lg:grid lg:grid-cols-3">
          <div className="col-span-2 w-full border-r border-secondaryPale">
            <div className="flex items-center justify-around">
              <GatsbyImage image={getImage(cms.pictures[0])} />
            </div>
          </div>
          <div className="layout my-auto">
            <ComponentMotion viewThreshold={0.1} yMotion={200}>
              <div className="mx-auto w-full lg:w-4/5 pt-6">
                <h1 className="uppercase text-2xl lg:text-4xl tracking-widest font-extrabold text-secondary">
                  {cms.title}
                </h1>
                <div className="my-6 lg:mt-12">
                  <h3 className="sr-only">Description</h3>
                  {cms.description &&
                    renderRichText(cms.description, richTextOptions)}
                </div>
              </div>
            </ComponentMotion>
          </div>
        </div>
      </section>

      <section className="bg-primary border-b border-secondaryPale">
        <div className="layout mx-auto py-12 lg:py-24 ">
          <ComponentMotion viewThreshold={0.1} yMotion={200}>
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
              <div>
                <h1 className="text-xl lg:text-4xl tracking-wide text-secondary">
                  {cms.node_locale === "fr-CA" ? "Détails" : "Details"}
                </h1>
              </div>
              {/* Product info */}
              <div className="mt-10 lg:px-4 px-0 sm:mt-16 lg:mt-0">
                <div className="mt-3">
                  <h2 className="sr-only">Product information</h2>
                  <p className="text-3xl font-medium">{price}</p>
                </div>

                <form className="mt-6">
                  {/* Colors */}
                  {/* <div>
                <h3 className="text-sm text-gray-600">Color</h3>

                <RadioGroup
                  value={selectedColor}
                  onChange={setSelectedColor}
                  className="mt-2"
                >
                  <RadioGroup.Label className="sr-only">
                    Choose a color
                  </RadioGroup.Label>
                  <span className="flex items-center space-x-3">
                    {product.colors.map((color) => (
                      <RadioGroup.Option
                        key={color.name}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedColor,
                            active && checked ? "ring ring-offset-1" : "",
                            !active && checked ? "ring-2" : "",
                            "-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
                          )
                        }
                      >
                        <RadioGroup.Label as="span" className="sr-only">
                          {color.name}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            color.bgColor,
                            "h-8 w-8 border border-black border-opacity-10 rounded-full"
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </span>
                </RadioGroup>
              </div> */}
                </form>

                <div className="mt-10 flex flex-col lg:flex-row lg:justify-between lg:items-center text-sm lg:text-base ">
                  <div>
                    <fieldset className="">
                      {hasVariants &&
                        options.map(({ id, name, values }, index) => (
                          <div className="py-1" key={id}>
                            <select
                              aria-label="Variants"
                              onChange={(event) =>
                                handleOptionChange(index, event)
                              }
                              className=" py-3 px-2 bg-black/[.05]"
                            >
                              <option value="">
                                {cms.node_locale === "fr-CA"
                                  ? `${name}`
                                  : `${name}`}
                              </option>
                              {values.map((value) => (
                                <option value={value} key={`${name}-${value}`}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        ))}
                    </fieldset>
                    <NumericInput
                      aria-label="Quantity"
                      onIncrement={() =>
                        setQuantity((q) => Math.min(q + 1, 20))
                      }
                      onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                      onChange={(event) =>
                        setQuantity(event.currentTarget.value)
                      }
                      value={quantity}
                      min="1"
                      max="20"
                    />
                  </div>
                  <AddToCart
                    variantId={productVariant.storefrontId}
                    quantity={quantity}
                    available={available}
                    textAvailable={
                      cms.node_locale === "fr-CA"
                        ? "Ajouter au panier"
                        : "Add to cart"
                    }
                    textNotAvailable={
                      cms.node_locale === "fr-CA"
                        ? "Rupture de stock"
                        : "Out of stock"
                    }
                  />
                </div>

                <section aria-labelledby="details-heading" className="mt-12">
                  <h2 id="details-heading" className="sr-only">
                    Additional details
                  </h2>

                  <div className="border-t divide-y divide-secondary border-secondary">
                    {cms.detailsDimensions && (
                      <Disclosure as="div">
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                                <span
                                  className={classNames(
                                    open ? "text-tertiary" : "text-gray-900",
                                    "text-base lg:text-lg font-medium"
                                  )}
                                >
                                  {cms.node_locale === "fr-CA"
                                    ? "Dimensions"
                                    : "Dimensions"}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusSmIcon
                                      className="block h-6 w-6 text-tertiary group-hover:text-tertiary"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmIcon
                                      className="block h-6 w-6 text-secondary group-hover:text-tertiary"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              as="div"
                              className="pb-6 prose prose-sm"
                            >
                              {cms.detailsDimensions &&
                                renderRichText(
                                  cms.detailsDimensions,
                                  richTextOptions
                                )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {cms.detailsShipping && (
                      <Disclosure as="div">
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                                <span
                                  className={classNames(
                                    open ? "text-tertiary" : "text-gray-900",
                                    "text-base lg:text-lg font-medium"
                                  )}
                                >
                                  {cms.node_locale === "fr-CA"
                                    ? " Délais et Livraison"
                                    : "Time and Delivery"}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusSmIcon
                                      className="block h-6 w-6 text-tertiary group-hover:text-tertiary"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmIcon
                                      className="block h-6 w-6 text-tertiary group-hover:text-tertiary"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              as="div"
                              className="pb-6 prose prose-sm"
                            >
                              {cms.detailsShipping &&
                                renderRichText(
                                  cms.detailsShipping,
                                  richTextOptions
                                )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                    {cms.detailsMaintenance && (
                      <Disclosure as="div">
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                                <span
                                  className={classNames(
                                    open ? "text-tertiary" : "",
                                    "text-base lg:text-lg font-medium"
                                  )}
                                >
                                  {cms.node_locale === "fr-CA"
                                    ? "Finition et Entretien"
                                    : "Finishing and Maintenance"}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusSmIcon
                                      className="block h-6 w-6 text-tertiary group-hover:text-tertiary"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusSmIcon
                                      className="block h-6 w-6 text-tertiary group-hover:text-tertiary"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              as="div"
                              className="pb-6 prose prose-sm"
                            >
                              {cms.detailsMaintenance &&
                                renderRichText(
                                  cms.detailsMaintenance,
                                  richTextOptions
                                )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </ComponentMotion>
        </div>
      </section>

      <section className="bg-secondary">
        <ComponentMotion viewThreshold={0.1} yMotion={200}>
          <div className="layout mx-auto py-12 lg:py-24 pb-3">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
              <div>
                <h1 className="text-xl lg:text-4xl tracking-wide text-primary">
                  {cms.node_locale === "fr-CA" ? "Photos" : "Pictures"}
                </h1>
              </div>
              {/* Image gallery */}
              <Tab.Group
                as="div"
                className="flex flex-col-reverse mt-10 lg:mt-0"
              >
                {/* Image selector */}
                <div className="hidden mt-6 w-full mx-auto sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-6">
                    {images.map((image, index) => (
                      <Tab
                        key={`product-image-${image.id}`}
                        className="relative h-24 flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-2 focus:ring-opacity-50"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only">{image.name}</span>
                            <span className="absolute inset-0  overflow-hidden bg-white rounded-lg">
                              <GatsbyImage
                                image={image.gatsbyImageData}
                                alt={
                                  image.altText
                                    ? image.altText
                                    : `Product Image of ${title} #${index + 1}`
                                }
                                className="w-full h-full object-center object-cover rounded-lg"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-secondary rounded-lg"
                                  : "ring-transparent rounded-lg",
                                "absolute inset-0  ring-2 ring-offset-2 pointer-events-none rounded-lg"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels className="hidden sm:block w-full aspect-w-1 aspect-h-1  bg-white rounded-lg">
                  {product.images.map((image) => (
                    <Tab.Panel key={image.id}>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.alt}
                        className="w-full h-full object-center object-cover rounded-lg "
                      />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>

          {hasImages && (
            <div className="block md:hidden">
              <div
                role="group"
                aria-label="gallery"
                aria-describedby="instructions"
                className="my-0 md:my-12 bg-white rounded-lg"
              >
                <ul className={productImageList}>
                  {images.map((image, index) => (
                    <li
                      key={`product-image-${image.id}`}
                      className={productImageListItem}
                    >
                      <GatsbyImage
                        objectFit="contain"
                        className="object-center object-cover rounded-lg"
                        loading={index === 0 ? "eager" : "lazy"}
                        alt={
                          image.altText
                            ? image.altText
                            : `Product Image of ${title} #${index + 1}`
                        }
                        image={image.gatsbyImageData}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {hasMultipleImages && (
                <div className={scrollForMore} id="instructions">
                  <span aria-hidden="true">←</span> scroll for more{" "}
                  <span aria-hidden="true">→</span>
                </div>
              )}
            </div>
          )}
        </ComponentMotion>
      </section>
    </section>
  )
}

export default ProductInfo
