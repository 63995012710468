import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../layouts/layout"
import ProductInfo from "../components/products/product-info"
import { Seo } from "../components/seo"
import { getSrc } from "gatsby-plugin-image"

const ProductPage = ({ data }) => {
  // Linking page product with Shopify product
  const productId = atob(data.page.shopifyLink)
  const allShopifyItem = data.product.nodes
  const shopifyItem = allShopifyItem.filter(
    (node) => node.storefrontId === productId
  )

  let isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = data.context.context.pathNoLanguagePrefix

  return (
    <Layout isEnglish={isEnglish} path={path}>
      <ProductInfo product={shopifyItem[0]} cms={data.page} />
      {/* <ProductSandbox product={shopifyItem[0]} page={data.page} /> */}
    </Layout>
  )
}

export default ProductPage

export const Head = ({ data }) => (
  <Seo
    title={data.page.title}
    image={getSrc(data.page.pictures[0].gatsbyImageData)}
  />
)

export const query = graphql`
  query ProductPage($slug: String!, $nodeLocale: String!) {
    page: contentfulProductItem(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      id
      node_locale
      title
      pictures {
        gatsbyImageData
      }
      shopifyLink
      description {
        raw
      }
      detailsDimensions {
        raw
      }
      detailsMaintenance {
        raw
      }
      detailsShipping {
        raw
      }
      slug
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
    product: allShopifyProduct {
      nodes {
        title
        description
        productType
        tags
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        images {
          # altText
          id
          gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
        }
        variants {
          availableForSale
          storefrontId
          title
          price
          selectedOptions {
            name
            value
          }
        }
        options {
          name
          values
          id
        }
      }
    }
  }
`
