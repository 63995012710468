import * as React from "react"
import { StoreContext } from "../../context/store-context"

export function AddToCart({
  variantId,
  textAvailable,
  textNotAvailable,
  quantity,
  available,
  ...props
}) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <button
      type="submit"
      className="mt-3 lg:mt-0 max-w-xs flex-1 bg-secondary border border-transparent rounded-md py-3 px-5 flex items-center justify-center font-medium text-white hover:bg-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-secondary sm:w-full"
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? `${textAvailable}` : `${textNotAvailable}`}
    </button>
  )
}
